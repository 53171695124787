import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_fastenaktion',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.navigation')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionText',
          style: {
            text: ['color--lg']
          },
          content: {
            text: i18n.getLocalesTranslations('literal.login_register_and_profile')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            wrapper_title: 'link.text',
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'subtitle',
          content: {
            label: i18n.getLocalesTranslations('literal.subtitle')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'policy_navigation',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            wrapper_title: 'link.text',
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.newsletter')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'form',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.form')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'element',
              content: {
                label: i18n.getLocalesTranslations('literal.element')
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'subtitle',
          content: {
            label: i18n.getLocalesTranslations('literal.subtitle')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'address',
          settings: {
            layout: 'inline'
          },
          content: {
            label: i18n.getLocalesTranslations('literal.address'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_line')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'line',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'contact',
          content: {
            label: i18n.getLocalesTranslations('literal.contact'),
            btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
            wrapper_title: 'text'
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'type',
              content: {
                label: i18n.getLocalesTranslations('literal.type')
              },
              value: 'text',
              settings: {
                hide_label: true,
                options: [
                  {
                    label: i18n.getLocalesTranslations('literal.text'),
                    value: 'text'
                  },
                  {
                    label: i18n.getLocalesTranslations('literal.email'),
                    value: 'email'
                  },
                  {
                    label: i18n.getLocalesTranslations('literal.phone_number'),
                    value: 'phone_number'
                  }
                ]
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'prefix',
              content: {
                label: {
                  'fr-FR': 'Préfixe',
                  'de-DE': 'Präfix',
                  'en-GB': 'Prefix'
                }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionText',
          style: {
            text: ['color--lg']
          },
          content: {
            text: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    }
  ]
}
