import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_big',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #1', 'fr-FR': 'Colonne #1', 'nl-NL': 'Kolom #1' }
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 9999, height: 50 },
            hide_label: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.organizational_info')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          },
          settings: {
            configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #2', 'fr-FR': 'Colonne #2', 'nl-NL': 'Kolom #2' }
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.menu')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'nav',
          settings: {
            layout: 'inline',
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #3', 'fr-FR': 'Colonne #3', 'nl-NL': 'Kolom #3' }
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'partner.logo',
          settings: {
            crop: false,
            size: { width: 9999, height: 250 },
            hide_label: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'partner.name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'partner.url'
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: { 'en-GB': 'Column #4', 'fr-FR': 'Colonne #4', 'nl-NL': 'Kolom #4' }
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'partner.logo',
          settings: {
            crop: false,
            size: { width: 9999, height: 250 },
            hide_label: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'partner.name',
          content: {
            label: i18n.getLocalesTranslations('literal.name')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'partner.url'
        }
      ]
    }
  ]
}