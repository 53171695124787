import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_fastenaktion',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'image',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          },
          settings: {
            crop: false,
            size: { width: 9999, height: 80 }
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'minial_image',
          content: {
            label: i18n.getLocalesTranslations('literal.mobile_logo')
          },
          settings: {
            crop: false,
            size: { width: 9999, height: 47 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'modules/pages/SectionRepeater',
      model: 'navigation',
      settings: {
        hide_label: true,
        layout: 'inline'
      },
      content: {
        wrapper_title: 'link.text',
        btn_label: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionDivider',
      style: {
        divider: ['separator', 'separator__size--full', 'bg__color--vdw']
      }
    },
    {
      path: 'modules/pages/SectionTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.call_to_action')
      }
    },
    {
      path: 'modules/pages/SectionButton',
      model: 'cta',
      settings: {
        hide_label: true
      }
    }
  ]
}
